/*-----Gallery-----*/

.demo-gallery {
	>ul {
		margin-bottom: 0;
		>li {
			float: left;
			margin-bottom: 15px;
			width: 200px;
			border: 0;
			a {
				border: 3px solid #FFF;
				border-radius: 3px;
				display: block;
				overflow: hidden;
				position: relative;
				float: left;
				>img {
					-webkit-transition: -webkit-transform 0.15s ease 0s;
					-moz-transition: -moz-transform 0.15s ease 0s;
					-o-transition: -o-transform 0.15s ease 0s;
					transition: transform 0.15s ease 0s;
					-webkit-transform: scale3d(1, 1, 1);
					transform: scale3d(1, 1, 1);
					height: 100%;
					width: 100%;
				}
				&:hover {
					>img {
						-webkit-transform: scale3d(1.1, 1.1, 1.1);
						transform: scale3d(1.1, 1.1, 1.1);
					}
					.demo-gallery-poster>img {
						opacity: 1;
					}
				}
				.demo-gallery-poster {
					background-color: $black-1;
					bottom: 0;
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
					-webkit-transition: background-color 0.15s ease 0s;
					-o-transition: background-color 0.15s ease 0s;
					transition: background-color 0.15s ease 0s;
					>img {
						left: 50%;
						margin-left: -10px;
						margin-top: -10px;
						opacity: 0;
						position: absolute;
						top: 50%;
						-webkit-transition: opacity 0.3s ease 0s;
						-o-transition: opacity 0.3s ease 0s;
						transition: opacity 0.3s ease 0s;
					}
				}
				&:hover .demo-gallery-poster {
					background-color: $black-5;
				}
			}
		}
	}
	.justified-gallery>a {
		>img {
			-webkit-transition: -webkit-transform 0.15s ease 0s;
			-moz-transition: -moz-transform 0.15s ease 0s;
			-o-transition: -o-transform 0.15s ease 0s;
			transition: transform 0.15s ease 0s;
			-webkit-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
			height: 100%;
			width: 100%;
		}
		&:hover {
			>img {
				-webkit-transform: scale3d(1.1, 1.1, 1.1);
				transform: scale3d(1.1, 1.1, 1.1);
			}
			.demo-gallery-poster>img {
				opacity: 1;
			}
		}
		.demo-gallery-poster {
			background-color: $black-1;
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			-webkit-transition: background-color 0.15s ease 0s;
			-o-transition: background-color 0.15s ease 0s;
			transition: background-color 0.15s ease 0s;
			>img {
				left: 50%;
				margin-left: -10px;
				margin-top: -10px;
				opacity: 0;
				position: absolute;
				top: 50%;
				-webkit-transition: opacity 0.3s ease 0s;
				-o-transition: opacity 0.3s ease 0s;
				transition: opacity 0.3s ease 0s;
			}
		}
		&:hover .demo-gallery-poster {
			background-color: $black-5;
		}
	}
	.video .demo-gallery-poster img {
		height: 48px;
		margin-left: -24px;
		margin-top: -24px;
		opacity: 0.8;
		width: 48px;
	}
	&.dark>ul>li a {
		border: 3px solid #04070a;
	}
}
.home .demo-gallery {
	padding-bottom: 80px;
}
.carousel-inner>.item> {
	img, a>img {
		width: 100%;
	}
}
.col-sm-3 a {
	border: 1px solid transparent;
	border-radius: 0;
	transition: all 3s ease;
	&:hover {
		border: 1px solid #ff4647;
		border-radius: 100% 60% / 30% 10%;
		background: linear-gradient(rgba(56, 123, 131, 0.7), rgba(56, 123, 131, 0.7));
	}
}
.gallery-list {
	list-style-type: none;
	margin: -5px -5px 15px;
	padding: 0;
	>li {
		float: left;
		width: 23%;
		padding: 10px;
		margin: 10px;
		border: 1px solid #efefef;
		.image-container {
			border-radius: 5px;
			background: $white;
			position: relative;
		}
		.image {
			padding-top: 60%;
			position: relative;
			overflow: hidden;
			border-radius: 5px 5px 0 0;
			img {
				max-width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				z-index: 1;
			}
		}
		.btn-list {
			position: absolute;
			left: 10px;
			top: 10px;
			right: 10px;
			z-index: 1;
		}
		.info {
			padding: 8px 10px;
			* {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			h5 {
				margin: 0;
				font-size: 12px;
			}
		}
	}
}
@media (max-width: 991px) {
	.gallery-list>li {
		width: 45.33%;
	}
}
@media (max-width: 480px) {
	.gallery-list>li {
		width: 100%;
	}
}
/*-----Lists-----*/

.list-group {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
}
.list-group-item-action {
	width: 100%;
	color: #6b6f80;
	text-align: inherit;
	&:hover, &:focus {
		color: #6b6f80;
		text-decoration: none;
		background-color: #f4f5fb;
		z-index: 0;
	}
	&:active {
		color: #6b6f80;
		background-color: #f4f5fb;
		z-index: 0;
	}
}
.list-group-item+.list-group-item {
	border-top-width: 1px;
}
.listorder {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: $white;
	border: 1px solid #eceff3;
	list-style-type: decimal;
	list-style-position: inside;
}
.listorder1 {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: $white;
	border: 1px solid #eceff3;
	list-style-type: upper-alpha;
	list-style-position: inside;
}
.listunorder {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: $white;
	border: 1px solid #eceff3;
	list-style-type: circle;
	list-style-position: inside;
}
.listunorder1 {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: $white;
	border: 1px solid #eceff3;
	list-style-type: square;
	list-style-position: inside;
}
.list-group-item {
	position: relative;
	display: block;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: $white;
	border: 1px solid #eceff3;
	&.active .icon {
		color: inherit !important;
	}
	.icon {
		color: #6b6f80 !important;
	}
	&:first-child {
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}
	&:last-child {
		margin-bottom: 0;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
	}
	&:hover, &:focus {
		z-index: 1;
		text-decoration: none;
	}
	&.disabled, &:disabled {
		color: #7c90b1;
		background-color: $white;
	}
	&.active {
		z-index: 0;
		color: inherit;
		border-color: transparent;
		background-color: #f4f5fb;
		border-color: 3px solid rgba(238, 238, 238, 0.5);
	}
}
.list-group-flush {
	.list-group-item {
		border-right: 0 !important;
		border-left: 0 !important;
		border-radius: 0;
	}
	&:first-child .list-group-item:first-child {
		border-top: 0;
	}
	&:last-child .list-group-item:last-child {
		border-bottom: 0;
	}
}
.list-group-item-primary {
	color: #24426c;
	background-color: #cbdbf2 !important;
	&.list-group-item-action {
		&:hover, &:focus {
			color: #24426c;
			background-color: #b7cded;
		}
		&.active {
			color: $white;
			background-color: #24426c;
			border-color: #24426c;
		}
	}
}
.list-group-item-secondary {
	color: $white;
	background-color: $secondary !important;
	&.list-group-item-action {
		&:hover, &:focus {
			color: $white;
			background-color: $secondary;
		}
		&.active {
			color: $white;
			background-color: $secondary;
			border-color: $secondary;
		}
	}
}
.list-group-item-success {
	color: #316100;
	background-color: #d2ecb8 !important;
	&.list-group-item-action {
		&:hover, &:focus {
			color: #316100;
			background-color: #c5e7a4;
		}
		&.active {
			color: $white;
			background-color: #316100;
			border-color: #316100;
		}
	}
}
.list-group-item-info {
	color: #24587e;
	background-color: #cbe7fb !important;
	&.list-group-item-action {
		&:hover, &:focus {
			color: #24587e;
			background-color: #b3dcf9 !important;
		}
		&.active {
			color: $white;
			background-color: #24587e;
			border-color: #24587e;
		}
	}
}
.list-group-item-warning {
	color: #7d6608;
	background-color: #fbeebc !important;
	&.list-group-item-action {
		&:hover, &:focus {
			color: #7d6608;
			background-color: #fae8a4;
		}
		&.active {
			color: $white;
			background-color: #7d6608;
			border-color: #7d6608;
		}
	}
}
.list-group-item-danger {
	color: #6b1110;
	background-color: #f1c1c0 !important;
	&.list-group-item-action {
		&:hover, &:focus {
			color: #6b1110;
			background-color: #ecacab !important;
		}
		&.active {
			color: $white;
			background-color: #6b1110;
			border-color: #6b1110;
		}
	}
}
.list-group-item-light {
	color: #818182;
	background-color: #fdfdfe;
	&.list-group-item-action {
		&:hover, &:focus {
			color: #818182;
			background-color: #ececf6;
		}
		&.active {
			color: $white;
			background-color: #818182;
			border-color: #818182;
		}
	}
}
.list-group-item-dark {
	color: #1b1e21;
	background-color: #c6c8ca;
	&.list-group-item-action {
		&:hover, &:focus {
			color: #1b1e21;
			background-color: #b9bbbe;
		}
		&.active {
			color: $white;
			background-color: #1b1e21;
			border-color: #1b1e21;
		}
	}
}
.close {
	float: right;
	font-size: 1.40625rem;
	font-weight: 700;
	line-height: 1;
	color: #495057;
	opacity: .5;
	&:hover, &:focus {
		color: $black;
		text-decoration: none;
		opacity: .75;
	}
	&:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
}
button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
	text-shadow: none;
	-webkit-appearance: none;
}
.list-inline-dots .list-inline-item+.list-inline-item:before {
	content: 'Â· ';
	margin-left: -2px;
	margin-right: 3px;
}
.list-separated-item {
	padding: 1rem 0;
	&:first-child {
		padding-top: 0;
	}
}
.list-group-transparent .list-group-item {
	background: none;
	border: 0;
	padding: .75rem 1rem;
	border-radius: 3px;
	&.active {
		background: #f4f5fb;
		color: $primary;
		font-weight: 600;
	}
}
.visitor-list {
	p {
		font-size: 12px;
	}
	i {
		font-size: 20px;
	}
}
.sales-relative {
	position: relative;
}
.icon-absolute {
	position: absolute;
	display: block;
	bottom: 10px;
	right: 10px;
	padding: 20px 0;
	text-align: center;
	border-radius: 50%;
	width: 70px;
	height: 70px;
}
@media (min-width: 991px) and (max-width: 1024px) {
	.icon-absolute {
		display: none;
	}
}